// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import '../../mixins/mixins';
@import '../../variables/colors';


.induxtry-tooltip .tooltip-inner {
    color: $white;
    @include text-inter-small;
    opacity: 0.8;
    
}

.induxtry-tooltip.bs-tooltip-end .tooltip-arrow::before {
    opacity: 0.8;
}
.induxtry-tooltip.bs-tooltip-start .tooltip-arrow::before {
    opacity: 0.8;
}
.induxtry-tooltip.bs-tooltip-top .tooltip-arrow::before {
    opacity: 0.8;
}
.induxtry-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    opacity: 0.8;
}