// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import '../../mixins/mixins';
@import '../../variables/colors.scss';

// -----------------------------------------------------------------------------------------------------
// @ Mat Radio
// -----------------------------------------------------------------------------------------------------

.mat-radio-label-content{
  // @include mat-label;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle{
  border-color: $blue-500;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle{
  background-color: $blue-500;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $blue-500;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  background-color: $blue-500;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: $blue-400 !important;
}


