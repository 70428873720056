// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import '../../mixins/mixins';

// -----------------------------------------------------------------------------------------------------
// @ Mat Checkbox
// -----------------------------------------------------------------------------------------------------

.mat-checkbox-layout .mat-checkbox-label{
  font-weight: normal;
  font-size: 14px;
  margin-top: 2px;
  color: $gray-500;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background{
  background-color: $gray-500;
  .mat-checkbox-checkmark{
    .mat-checkbox-checkmark-path{
      stroke: $white !important;
      stroke-width: 4px
    }
  }
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background{
  //background-color: $gray-800;
  background-color: transparent;
  .mat-checkbox-mixedmark{
    background-color: $gray-500 !important;
  }
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame{
  border-color: $gray-200 !important;
}
.mat-checkbox-disabled .mat-checkbox-label{
  color: $gray-200 !important;
}

// Dark

mat-checkbox.dark{
  .mat-checkbox-label{
    color: $white;
  }
  &:not(.mat-checkbox-checked){
    .mat-checkbox-frame{
      border-color: $white;
    }
  }
  &.mat-accent.mat-checkbox-checked{
    .mat-checkbox-frame{
      border-color: $primary-color-dark;
    }
  }
}
