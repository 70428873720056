
@mixin dialog-shadow {
  box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.167395);
}

@mixin clicked-button-shadow {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.08);
}

@mixin button-hover-shadow {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
  // box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.5);
}

@mixin button-focus-shadow {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

@mixin sub-menu-shadow {
  box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.12);
}

@mixin chat-field-shadow {
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);
}

@mixin cards-shadow {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
}
