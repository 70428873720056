// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

//...

// -----------------------------------------------------------------------------------------------------
// @ Mat Icons
// -----------------------------------------------------------------------------------------------------

.mat-icon {
  font-size: 24px;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
}