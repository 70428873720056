// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

// ...

// -----------------------------------------------------------------------------------------------------
// @ Mat Menu
// -----------------------------------------------------------------------------------------------------

.mat-menu-content:not(:empty) {
  padding: 6px 0 6px 0 !important;
  max-height: 200px;
  max-width: 212px;
  @media (max-width: 600px) {
    max-width: 184px;
    button.mat-menu-item {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
  button.mat-menu-item {
    width: 100%;
    height: 44px;
    line-height: 1;
    padding: 0 16px 0 14px
  }
  .mat-menu-item .mat-icon {
    margin-right: 10px;
    vertical-align: middle;
  }
}
