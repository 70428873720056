// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

//...

// -----------------------------------------------------------------------------------------------------
// @ Mat Expansion Panel - Default
// -----------------------------------------------------------------------------------------------------

.mat-expansion-panel-body {
    // padding: unset !important;
    padding: 0 0 16px !important;
}
