// -----------------------------------------------------------------------------------------------------
// @ Importation
// -----------------------------------------------------------------------------------------------------

@import "src/styles/variables/colors";

// -----------------------------------------------------------------------------------------------------
// @ Primary
// -----------------------------------------------------------------------------------------------------

$font-family-material: 'Material Icons';

$font-family-material-outlined: 'Material Icons Outlined';

@mixin text-font-inter {
  font-family: "Inter", "Inter Regular", Helvetica, Arial, sans-serif;
}

// Inter - Title
@mixin title-inter {
  font-family: "Inter", "Inter Regular", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin title-font {
  font-family: "Inter", Helvetica, Arial, sans-serif !important;
}

@mixin text-title-h3 {
  @include title-font;
  color: $gray-400;
  font-size: 16px;
}


@mixin title-inter-semibold {
  @include title-inter;
  font-weight: 600;
}

// Inter - Small
@mixin text-inter-small {
  @include text-font-inter;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

@mixin text-inter-small-semibold {
  @include text-inter-small;
  font-weight: 600;
}

// Inter - Medium
@mixin text-inter-medium {
  @include text-font-inter;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

@mixin text-inter-medium-semibold {
  @include text-inter-medium;
  font-weight: 600;
}

// Inter - Large
@mixin text-inter-large {
  @include text-font-inter;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin text-inter-large-semibold {
  @include text-inter-large;
  font-weight: 600;
}

// Inter - h5
@mixin text-inter-h5 {
  @include text-font-inter;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

@mixin text-inter-h5-semibold {
  @include text-inter-h5;
  font-weight: 600;
}

// Inter - h4
@mixin text-inter-h4 {
  @include text-font-inter;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

@mixin text-inter-h4-semibold {
  @include text-inter-h4;
  font-weight: 600;
}

// Inter - h3
@mixin text-inter-h3 {
  @include text-font-inter;
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
}

@mixin text-inter-h3-semibold {
  @include text-inter-h3;
  font-weight: 600;
}

// Inter - h2
@mixin text-inter-h2 {
  @include text-font-inter;
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
}

@mixin text-inter-h2-semibold {
  @include text-inter-h2;
  font-weight: 600;
}

// Inter - h1
@mixin text-inter-h1 {
  @include text-font-inter;
  font-size: 28px;
  line-height: 40px;
  font-weight: 400;
}

@mixin text-inter-h1-semibold {
  @include text-inter-h1;
  font-weight: 600;
}

// Inter - h0
@mixin text-inter-h0 {
  @include text-font-inter;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
}

@mixin text-inter-h0-semibold {
  @include text-inter-h0;
  font-weight: 600;
}
